.event-item {
  background-color: #fcf08b;

  display: flex;
  flex-direction: column;
  width: 20rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
}

.event-item__title {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
