.home-page h1 {
  font-family: "bungee_shaderegular", sans-serif;
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 4rem;
    line-height: 6rem;
  }
}

.home-page__section {
  background-image: linear-gradient(
    120deg,
    #ff9119 10%,
    #ff9d32 30%,
    #ffa94c 50%,
    #ffb566 70%,
    #ffc27f 90%
  );
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem;
}

.home-page__section--top {
  background-image: linear-gradient(
    to left,
    #fb893f,
    #fc8135,
    #fd782a,
    #fd6e20,
    #fe6414,
    #fe680f,
    #ff6c08,
    #ff7000,
    #ff8300,
    #ff9400,
    #ffa600,
    #ffb600
  );
}

.home-page__section--white {
  background-image: none;
  background-color: white;
}

.home-page__main-photo {
  margin-top: 2rem;
  width: 100%;
  border: 5px solid black;

  @media (min-width: 768px) {
    width: 40rem;
  }
}

.home-page__section p {
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
}

.home-page__image {
  width: 18rem;
}
