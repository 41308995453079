.facts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.facts-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.facts-item:not(last-child) {
  margin-bottom: 1rem;
}

.facts-item__icon {
  font-size: 1.5rem;
  padding: 0 1rem;
}
