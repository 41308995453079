.footer {
  background-color: black;
  color: white;
  font-size: 1rem;
}

.footer__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer,
.footer__container {
  height: 6rem;
}

.footer__links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__links a {
  color: white;
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 576px) {
    font-size: 2rem;
  }
}

.footer__links svg {
  margin-left: 1rem;
}
