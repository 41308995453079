.rounded-counter__number {
  background-color: #ff4800;
  background-image: linear-gradient(100deg, #ff4800, #ff6000, #ff7900);
  border-radius: 100%;
  border: 3px solid #ffb600;
  height: 12.5rem;
  width: 12.5rem;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 5rem;
}

.rounded-counter__date {
  margin-top: 1rem;
  font-weight: bold;

  display: flex;
  justify-content: center;
}
