.header {
  background-color: #fb893f;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 0;
  background-image: linear-gradient(
    to left,
    #fb893f,
    #fc8135,
    #fd782a,
    #fd6e20,
    #fe6414,
    #fe680f,
    #ff6c08,
    #ff7000,
    #ff8300,
    #ff9400,
    #ffa600,
    #ffb600
  );
}

.container.container--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
}

.header,
.container--header {
  height: 5rem;
}

.header__image {
  height: 4rem;
  cursor: pointer;

  @media (min-width: 576px) {
    height: 100%;
  }
}

.header__navigation--mobile-toggle {
  font-size: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media (min-width: 576px) {
    display: none;
  }
}

.header__navigation--mobile-popup {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: black;
  position: fixed;
  top: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header__navigation--mobile-popup .navigation-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__navigation--mobile-popup .navigation-items a {
  font-size: 2rem;
  color: black;
  background-color: white;
  text-decoration: none;
  font-family: "bungee_shaderegular", sans-serif;
  padding: 0 2rem;

  &:not(last-child) {
    margin-bottom: 2rem;
  }
}

.header__navigation--desktop {
  display: none;

  @media (min-width: 576px) {
    display: flex;
    align-items: center;
  }
}

.header__navigation--desktop a {
  color: black;
  text-decoration: none;
  margin-right: 1rem;
  font-weight: bold;
}

.header__navigation--desktop a.active {
  color: white;
}

.header__navigation__close {
  position: fixed;
  top: 1rem;
  right: 1rem;
  font-size: 5rem;
  color: white;
}
