.member {
  max-width: 30rem;
  display: grid;
  grid-template-areas: "photo info" "extraordinary extraordinary";
  grid-template-columns: 10rem auto;
  grid-gap: 1rem;
  padding: 0.5rem;
  border: 1px solid #b74b04;
  box-shadow: 2px 2px 2px 2px #fb893f;
  border-radius: 10px;
}

.member .member__photo {
  width: 10rem;
  grid-area: photo;
}

.member .member__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: info;
  width: 100%;
}

.member .member__since {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.member .member__extraordinary {
  grid-area: extraordinary;
}

.member .member__name {
  margin-top: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
}

.member .member__task {
  color: #b74b04;
  font-weight: bold;
}
