@import "./fonts";

body {
  font-family: "Source Sans Pro", "Segoe UI", "Roboto", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

h1,
p {
  margin-top: 0;
}

h2 {
  font-family: "bungee_shaderegular", sans-serif;
  font-size: 1.8rem;
  line-height: 3rem;

  @media (min-width: 576px) {
    font-size: 2.2rem;
  }
}

p,
ol {
  line-height: 1.5rem;
}

.container {
  max-width: 70rem;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.content {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
