@font-face {
  font-family: "bungee_shaderegular";
  src: url("bungeeshade-regular-webfont.woff2") format("woff2"),
    url("bungeeshade-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("SourceSansPro-Regular.woff2") format("woff2"),
    url("SourceSansPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
