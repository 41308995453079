.accordion {
  border-radius: 1rem;
  background-color: #fef8f3;
}

.accordion__title {
  background-color: #cbbeb5;
  border-radius: 1rem;

  padding: 0 1rem;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion__content {
  padding: 1rem;
}
